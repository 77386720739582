export default {
    formataValor(valor, cifra) {
        var val = (valor / 1).toFixed(2).replace('.', ',');
        if (cifra == '') {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            return cifra + ' ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    reqAxios(parametros, metodo, url){
        let axios        = require('axios');
        let bodyFormData = new FormData(); 

        for (let i = 0; i < parametros.length; i++){
            let pmt = parametros[i].parametro;
            let vl  = parametros[i].valor;
            bodyFormData.append(pmt, vl);    
        }
        return axios({
            method: metodo,
            // url: 'https://www.rillsoft-app.cloud/'+url,
            url : 'https://www.'+url,
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }            
        });           
    },
    deslogarUsuario(vself){
        vself.$store.commit('MUDAR_USUARIO_LOGADO', {
            id			: null,
            cnpjcpf		: null,
            fantasia	: '',
            tpcliente	: 0,
        });
        window.localStorage.removeItem('usuarioLogado');
        vself.$router.push({ name: 'AcessarView' });
    }
}