import utils from './utils.js';

export default {
    async carregaUsuarioLogado(context) {
        let vself = context;
        let dadosUsuario = (JSON.parse(window.localStorage.getItem('usuarioLogado')))
        let parametros = [
            {parametro: 'op',           	valor: 'carregausuariologado'},
            {parametro: 'codcli',           valor: dadosUsuario.id},
        ];
        let retorno = utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/rillpay/rtacesso.php');
        retorno.then(function (response) {

            let payload = {
                id			: response.data.codcli,
                cnpjcpf		: response.data.cnpjcpf,
                fantasia	: response.data.fantasiacli,
                tpcliente	: response.data.tpcli,
                imgcliente  : response.data.imgcliente
            };
            vself.$store.commit('MUDAR_USUARIO_LOGADO', payload);

            window.localStorage.setItem('usuarioLogado', JSON.stringify(payload));
            return payload;
        }).catch(function (response) {
            vself.$toastr.e('Erro ao logar cliente.', 'Ops!');
            console.log(response.message);
            return payload;
        });
    }
}