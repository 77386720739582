import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		usuarioLogado: {
			id			: null,
			cnpjcpf		: null,
			fantasia	: '',
			tpcliente	: 0,
			imgcliente  : '',
		},
	},
	getters: {},
	mutations: {
		MUDAR_USUARIO_LOGADO(state, payload) {
			state.usuarioLogado = payload;
		},
	},
	actions: {},
	modules: {},
});
