<template>
    <div id="headermenu">
        <nav class="navbar-header">
            <div class="row">
                <div class="col-1 col-sm-1 col-md-5 col-lg-5">
                    <div class="icone-menu">
                        <!-- <i class="bi bi-list"></i> -->
                    </div>
                </div>
                <div class="col-10 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-center align-items-center">
                    <div class="logo-rillpay">
                        <img src="@/assets/logo-rillpay-white.png" @click.prevent="$router.push({name: 'home'})" alt="Logo Rillpay">
                    </div>
                </div>
                <div class="col-11 col-sm-9 col-md-5 col-lg-5">
                    <div class="dropdown d-flex justify-content-end">
                        <button class="btn btn-secondary dropdown-toggle btn-profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img :src="usuarioLogado.imgcliente == '' ? 'https://rillsoft.app/img/default-avatar-profile.4252016b.jpg' : usuarioLogado.imgcliente" alt="Foto de perfil cliente"> 
                            <span>{{usuarioLogado.id}} - {{usuarioLogado.fantasia}}</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <div class="dropdown-item dropitem-profile">
                                    <img :src="usuarioLogado.imgcliente == '' ? 'https://rillsoft.app/img/default-avatar-profile.4252016b.jpg' : usuarioLogado.imgcliente" alt=""> 
                                </div>
                            </li>
                            <!-- <li><a class="dropdown-item" href="#"><i class="bi bi-key"></i> Alterar acesso</a></li> -->
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item" @click.prevent="deslogarUsuario()" href="#" style="color: #bb0000;"><i class="bi bi-box-arrow-right"></i> Sair</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import utils from '@/utilitarios/utils.js'

export default {
    name: 'HeaderMenu',
    props: {
    },
    methods: {
        deslogarUsuario() {
            utils.deslogarUsuario(this);
        }
    },
    mounted() {
        if (window.localStorage.getItem('usuarioLogado')){      
            let payload = (JSON.parse(window.localStorage.getItem('usuarioLogado')))   
            this.$store.commit('MUDAR_USUARIO_LOGADO', payload);
        } else {
            this.$router.push({name: 'AcessarView'});
        }
    },
    computed: {
        usuarioLogado(){
            return this.$store.state.usuarioLogado;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-header{
    background-color: #1e2b37;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.411);
    color: rgb(255, 255, 255);
}

.icone-menu{
    width: 30px;
    font-size: 30px;
}

.logo-rillpay{
    & > img {
        margin-left: 20px;
        width: 100px;
    }
    cursor: pointer;
}

.btn-profile{
    background-color: transparent;
    border: none;
    
    & > img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    & > span{
        margin-left: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: .8rem;
        text-wrap: auto;
    }
}

.dropitem-profile{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    & > img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}
</style>
