import $ from "jquery"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueToastr from "vue-toastr"                    // TOASTR
import VueQRCodeComponent from 'vue-qrcode-component' // QR CODE
import '@/scss/global.scss'                           // ESTILIZAÇÃO GLOBAL
import VueConfetti from 'vue-confetti'                // CONFETTI
import LottieAnimation from 'lottie-web-vue'          // LOTTIE ANIMATION

Vue.config.productionTip = false

window.$ = window.jQuery = $;
Vue.use(VueTheMask);
Vue.use(VueConfetti);
Vue.use(LottieAnimation);
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueToastr, {
  defaultStyle: {
    "margin-top": "25px",
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
