<template>
  <div id="app">
    <HeaderMenu v-if="$route.path !== '/acessar'"/>
    <router-view/>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderMenu from '@/components/HeaderMenu.vue' 
import bl from '@/utilitarios/beforeLoad.js'

export default {
  components: {
    HeaderMenu
  },
  async beforeMount() {
    if (window.localStorage.getItem('usuarioLogado') !== null) {      
      let payload = (JSON.parse(window.localStorage.getItem('usuarioLogado')))   
      this.$store.commit('MUDAR_USUARIO_LOGADO', payload);
    } 

    let ret1 = await bl.carregaUsuarioLogado(this);
  }
}
</script>
<style lang="scss">
</style>
