<template>
<div class="home">
    <!-- <nav aria-label="breadcrumb" class="nav-bc">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">Início</li>
		</ol>
    </nav> -->
    <div class="container">
        <div class="row mb-5">
			<div class="col-md-12">
				<div class="card cd-card">
					<div class="card-header table-header">
						<h4><i class="bi bi-card-list"></i> Resumo de Cobranças</h4>
					</div>
					<template v-if="isLoadingResumoCobrancas">
						<div class="row mt-4 d-flex pt-4 pb-5 justify-content-center ">
							<span class="loader-spinner"></span>
						</div>
					</template>
					<template v-else-if="!isLoadingResumoCobrancas && !isPagtoConcluidoPix">
						<div v-if="lstResumoCobrancas.length > 0" class="card-body">
							<!-- Título de instrução dizendo que deve selecionar os débitos e a forma de pagamento que deseja. -->
							<p>Selecione os débitos e a forma de pagamento que deseja utilizar:</p>
							<div class="lista-cobrancas">
								<ol class="list-group list-group">
									<li v-for="(cobranca, index) in lstResumoCobrancas" class="list-group-item d-flex justify-content-between align-items-start mb-2" :class="{'lst-desabilitada': isLoadingQrCodePix || codigoCopiaeCola}" style="background-color: #edf1f5;">
										<input class="cb-item-cobranca" :id="'item-lista-cobranca-'+index" type="checkbox" v-model="lstResumoCobrancasSelecionadas" @change="ProcessaCobrancasSelecionadas" :value="cobranca" :disabled="ativaDesativaSelecaoCobrancas"/>
										<label class="form-check-label ms-2 me-auto" :for="'item-lista-cobranca-'+index">
											<div class="fw-bold">{{ cobranca.codtit }}</div>
											<small v-if="!cobranca.ematraso">Vencimento: {{ cobranca.dtventit }}</small>
											<small v-else style="color: #8b0000;">Vencimento: {{ cobranca.dtventit }}</small>
										</label>
										<div class="d-flex align-items-center justify-content-center flex-column">
											<span class="badge bg-primary rounded-pill ms-auto" style="background-color: #1e2b37 !important;">{{ FormataValor(cobranca.vlresta, "R$") }}</span>
											<small v-show="cobranca.ematraso" class="text-danger fw-bold">Em atraso</small>
										</div>
									</li>
								</ol>
							</div>
							<hr class="hr hr-blurry" />
							<div class="total-cobrancas">
								<p>Total: <strong>{{ FormataValor(totalCobrancasSelecionadas, 'R$') }}</strong></p>
								<div class="formas-pagamento-resumo-cobrancas">
									<div class="row">
										<div class="col-md-12">
											<span>Escolha como deseja pagar:</span>
										</div>
									</div>
									<div class="row">
										<div class="btn-group d-flex flex-md-row flex-column" role="group" aria-label="Basic radio toggle button group">
											<input type="radio" class="btn-check" name="btnradioformapag" id="btnradioformapagpix" autocomplete="off" checked>
											<label class="btn btn-outline-primary mb-2" for="btnradioformapagpix">
												<i class="bi bi-qr-code-scan"></i> PIX
											</label>

											<input disabled type="radio" class="btn-check" name="btnradioformapag" id="btnradioformapagccredito" autocomplete="off">
											<label class="btn btn-outline-primary mb-2" for="btnradioformapagccredito">
												<i class="bi bi-credit-card"></i> Cartão de Crédito (EM BREVE)
											</label>

											<input disabled type="radio" class="btn-check" name="btnradioformapag" id="btnradioformapagboleto" autocomplete="off">
											<label class="btn btn-outline-primary mb-2" for="btnradioformapagboleto">
												<i class="bi bi-upc"></i> Boleto (EM BREVE)
											</label>
										</div>
									</div>
									<template v-if="exibeQrCode">
										<template v-if="isLoadingQrCodePix">
											<div class="row mt-4 d-flex pt-4 pb-5 justify-content-center loader-qrcode-pix">
												<span class="loader-spinner"></span>
												<p>Gerando pix...</p>
											</div>
										</template>
										<template v-else>
											<div class="row">
												<div class="col-12 infos-header-pix mt-4">
													<h2>Pagar <strong>{{ FormataValor(totalCobrancasSelecionadas, 'R$') }}</strong> para Rillsoft Sistemas</h2>
													<p>Abra o app do seu banco, copie e cole este código QR para fazer o pagamento:</p>
												</div>
												<div class="col-12 col-sm-12 col-md-6 col-lg-6" style="border-right: 1px solid #c2c2c2;">
													<div class="container-qrcode">
														<qr-code 
															:text="codigoCopiaeCola"
															:size="sizeQrCode"
															error-level="H"
															>
														</qr-code>
														<p v-show="avisoPixCopiado">Código copiado!</p>
													</div>
													<div class="btn-copiar-pix">
														<button class="btn btn-outline-primary" @click.prevent="copiarQRcode()"><i class="fa fa-clipboard" aria-hidden="true"></i> Copiar código QR code</button>
													</div>
												</div>
												<div class="col-12 col-sm-12 col-md-6 col-lg-6">
													<div class="infos-pix">
														<div class="info-pix">
															<p>Identificador:</p>
															<span>{{ identificadorPix }}</span>
														</div>
														<div class="info-pix">
															<p>Prazo de pagamento:</p>
															<span>{{ prazoPagamento }}</span>
														</div>
														<div class="info-pix">
															<p>Valor:</p>
															<span>{{ FormataValor(totalCobrancasSelecionadas, 'R$') }}</span>
														</div>
													</div>
													<div class="status-pagamento">
														<span class="loader-pix"></span>
														<p>Aguardando pagamento</p>
													</div>
													<div class="btn-cancelar">
														<button type="button" @click.prevent="cancelarPagamentoPix()" class="btn btn-primary">Voltar</button>
													</div>
												</div>
											</div>
										</template>
									</template>
								</div>
							</div>
							<hr class="hr hr-blurry" />
							<div v-show="!isLoadingQrCodePix && !codigoCopiaeCola" class="btns-footer">	
								<button type="button" @click.prevent="GeraCobrancaPix()" class="btn btn-primary">Pagar agora</button>
							</div>
						</div>
						<div v-else class="card-body">
							<div class="alert alert-warning" role="alert">
								Não há cobranças em aberto no momento.
							</div>
						</div>
					</template>
					<template v-else-if="!isLoadingResumoCobrancas && isPagtoConcluidoPix">
						<div class="animContainer">
							<LottieAnimation
								ref="animPix"
								:animationData="require('@/assets/alertsuccess.json')"
								:loop="false"
								:autoPlay="true"
							/>
							<span>Pagamento recebido!</span>
						</div>
					</template>
					<template>
						<!-- VERSÃO DO APP -->
						<div class="card-footer">
							<small class="text-center">Versão {{versaoApp}} - Desenvolvido e distribuido por Rillsoft Sistemas LTDA</small>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import io from 'socket.io-client';
import VueBootstrap4Table from 'vue-bootstrap4-table'
import utils from '@/utilitarios/utils'
import LottieAnimation from 'lottie-web-vue'

// @ is an alias to /src
export default {
	name: 'HomeView',
	components: {
		VueBootstrap4Table,
		LottieAnimation
	},
	data(){
		return {
			versaoApp						: process.env.VUE_APP_VERSION,

			isLoadingResumoCobrancas		: false,
			isLoadingQrCodePix				: false,
			exibeQrCode						: false,
			ativaDesativaSelecaoCobrancas	: false,

			lstResumoCobrancasSelecionadas	: [],
			totalCobrancasSelecionadas		: 0,
			lstResumoCobrancas				: [],

			codigoCopiaeCola        		: '',
            sizeQrCode              		: 220,
            avisoPixCopiado         		: false,
			identificadorPix				: '',
			prazoPagamento					: '',
			isPagtoConcluidoPix				: false,
			connSocketIO					: null,
		}
	},
	methods: {
		copiarQRcode(){
            navigator.clipboard.writeText(this.codigoCopiaeCola).then(
                () => {
                    this.avisoPixCopiado = true;
                    setTimeout(() => (this.avisoPixCopiado = false), 4000); 
                },
            );
        },
		FormataValor(valor, cifra){
			return utils.formataValor(valor, cifra);
		},
		FormatarDataHora(datahora) {
			const [data, hora] = datahora.split(' ');
			const [ano, mes, dia] = data.split('-');
			return `${dia}/${mes}/${ano} ${hora}`;
		},
		ProcessaCobrancasSelecionadas(){
			this.totalCobrancasSelecionadas = 0;
			this.lstResumoCobrancasSelecionadas.forEach(cobranca => {
				this.totalCobrancasSelecionadas += parseFloat(cobranca.vlresta);
			});
		},
		ListaCobrancasAberto(){
			let vself = this;
			vself.isLoadingResumoCobrancas = true;
			let parametros = [
				{parametro: 'op',				valor: 'listagemtitulosemaberto'},
				{parametro: 'codcli',			valor: this.$store.state.usuarioLogado.id},
			]

			let retorno = utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/rillpay/rthome.php');
			retorno.then(function (response) {
				vself.lstResumoCobrancas = response.data;

				vself.ProcessaCobrancasSelecionadas();
				setTimeout(() => {
					vself.isLoadingResumoCobrancas = false;
				}, 1000);
			}).catch(function (response) {
				vself.$toastr.e('Erro ao carregar cobranças em aberto.', 'Ops!');
				console.log(response.message);
				vself.lstResumoCobrancas = [];
				setTimeout(() => {
					vself.isLoadingResumoCobrancas = false;
				}, 1000);
			});
		},
		GeraCobrancaPix(){
			let vself = this;
			vself.exibeQrCode = true;
			vself.isLoadingQrCodePix = true;

			let parametros = [
				{parametro: 'op',				valor: 'geracobrancapix'},
				{parametro: 'codcli',			valor: this.$store.state.usuarioLogado.id},
				{parametro: 'lsttitulos',		valor: JSON.stringify(this.lstResumoCobrancasSelecionadas)},
			]

			let retorno = utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/rillpay/rthome.php');
			retorno.then(function (response) {
				vself.InitSocketIO();
				vself.codigoCopiaeCola 	= response.data.pixcopiaecola;
				vself.identificadorPix 	= response.data.txid;
				vself.prazoPagamento	= vself.FormatarDataHora(response.data.dtexpiracao);
				setTimeout(() => {
					vself.isLoadingQrCodePix = false;
				}, 1000);
			}).catch(function (response) {
				vself.CloseSocketIO();
				vself.$toastr.e('Erro ao gerar cobrança PIX.', 'Ops!');
				console.log(response.message);
				vself.isLoadingQrCodePix = false;
				vself.codigoCopiaeCola = '';
				vself.identificadorPix = '';
				vself.prazoPagamento = '';
				vself.exibeQrCode = false;
			});
		},
		ConcluiPagamentoPix(){
			this.$confetti.start();
			setTimeout(() => {
				this.$confetti.stop();
			}, 2500);
			this.isPagtoConcluidoPix = true;
			setTimeout(() => {
				this.ResetaTudo();
			}, 4000);
		},
		ResetaTudo(){
			this.isLoadingResumoCobrancas 		= false;
			this.isLoadingQrCodePix 			= false;
			this.exibeQrCode 					= false;

			this.lstResumoCobrancasSelecionadas	= [];
			this.totalCobrancasSelecionadas		= 0;
			this.lstResumoCobrancas				= [];

			this.codigoCopiaeCola        		= ''
            this.sizeQrCode              		= 220
            this.avisoPixCopiado         		= false
			this.identificadorPix				= ''
			this.prazoPagamento					= ''
			this.isPagtoConcluidoPix			= false
			this.connSocketIO					= null
			if(this.connSocketIO) this.connSocketIO.disconnect();

			this.ListaCobrancasAberto();
		},
		InitSocketIO(){
			if (this.connSocketIO ) {
				this.connSocketIO.disconnect()
			}

			this.connSocketIO = io('https://app-rillsoft.com.br');
            this.connSocketIO.on('SACRP-pagamento-recebido', (data) => {
                if (data.txid != this.identificadorPix) {
                    return;
                }
                this.connSocketIO.disconnect()
                this.ConcluiPagamentoPix();
            });

            this.connSocketIO.on('conectado', (message) => {
                console.log(message.message)
            });
		},
		CloseSocketIO(){
			if (this.connSocketIO) {
				this.connSocketIO.disconnect()
			}
		},
		cancelarPagamentoPix(){
			this.ResetaTudo();
		},
	},
	mounted(){
		this.ListaCobrancasAberto();
	}
}
</script>

<style scoped lang="scss">

.home {
	background-color: #edf1f5;
	min-height: 100vh;
	padding-top: 20px;
}

.table-header{
    background-color: #134779;
    color: white;
    h4{
        margin: 0;
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
    }
}

.nav-bc{
	display: flex;
	justify-content: center;
	align-items: center;

	ol {
		height: 45px;
		width: 90%;
		margin-top: 20px;
		border-radius: 10px;
		background: #ffffff;
	}
}

.cd-card {
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 7px 12px 13px -6px rgba(0,0,0,0.1);
}

.forma-pagamento-cobranca{
	width: 100%;
	background-color: #1e2b37;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 600;
}

.cb-item-cobranca {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	margin-top: 3px;
}

.btns-footer{
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;

	button{
		background-color: #1e2b37;
		font-weight: 600;
	}
}

.container-qrcode{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &>p{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
        background-color: #00a025;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.3s;
    }

    &>div{
        border-radius: 10px;
        border: 3.5px solid var(--cor-primaria);
        padding: 13px;
        width: fit-content;
        height: fit-content;
        background-color: #fff;
    }
}

.btn-copiar-pix{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    &>button{
        font-weight: 500;
        transition: all 0.3s;
        &:hover{
            background-color: #1e2b37;
        }
    }
}

.infos-header-pix{
	text-align: center;
	&>h2{
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 8px;
		&>strong {
			font-weight: 600;
			color: #000;
		}
	}
	&>p{
		font-size: 14px;
		font-weight: 400;
		color: #000;
	}
}

.infos-pix{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &>.info-pix{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        &>p{
            font-size: 14px;
            font-weight: 500;
            color: #6c6c6c;
            margin-bottom: 0;
        }
        &>span{
            font-size: 16px;
            font-weight: 600;
            color: #000;
        }
    }
}
.status-pagamento{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    &>p{
        font-size: 14px;
        font-weight: 500;
        color: #6c6c6c;
    }
}

.loader-qrcode-pix{
	display: flex;
	justify-content: center;
	align-items: center;
	&>p{
		font-size: 16px;
		font-weight: 500;
		color: #000;
		text-align: center;
		animation: pulse .9s infinite;
		margin-bottom: 0px;
		margin-top: 10px;
	}
	
}

.animContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 440px;
    width: 100%;
    &>div {
        width: 100%;
        height: 100%;
    }
    &>span {
        position: absolute;
        font-size: 30px;
        font-weight: 600;
        color: rgb(31, 147, 93);
        bottom: 20px;
    }
}

.lst-desabilitada{
	pointer-events: none;
	opacity: 0.5;
}

.btn-cancelar{
	display: flex;
	justify-content: flex-end;

	button{
		width: 100%;
		background-color: #1e2b3700;
		border: solid 3px #1e2b378f;
		color: #1e2b37;
		font-weight: 600;

		&:hover{
			background-color: #1e2b37;
			color: white;
		}
	}
}
</style>

