<template>
<div id="app-acesso">
    <div class="container container-acesso">
        <div class="row">
            <div class="col-md-12 container-imagem-perfil">
                <img src="@/assets/logo-rillpay.png" alt="Foto perfil usuário">
            </div>
            <div class="col-md-12 mt-2">
                <label v-if="fantasiaCliente" for="inp-cnpjcpf" style="text-align: center; width: 100%; color: #035c00">{{ fantasiaCliente }}</label>
                <label v-else for="inp-cnpjcpf" style="text-align: center; width: 100%;">Área do Cliente</label>
            </div>
        </div>
        <template v-if="isLoadingAcessar">
            <div class="row mt-4 d-flex justify-content-center">
                <span class="loader-spinner"></span>
            </div>
        </template>
        <template v-else>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="bi bi-search"></i></div>
                        </div>
                        <input type="text" class="form-control" id="inp-cnpjcpf" v-model="vCnpjCpf" @blur="carregaInformacoesInput()" v-mask="['###.###.###-##', '##.###.###/####-##']" placeholder="CNPJ ou CPF" style="box-shadow: none">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="input-group mb-2 mr-sm-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="bi bi-key"></i></div>
                        </div>
                        <input type="password" maxlength="4" class="form-control" id="inp-cnpjcpf" @keydown.enter="logarCliente" v-model="vChaveAcesso" placeholder="Chave de acesso" style="box-shadow: none">
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <button type="button" class="btn btn-dark" @click.prevent="logarCliente()" style="width: 100%;">Acessar</button>
                </div>
            </div>
        </template>
        <div class="row">
            <div class="col-md-12 d-flex flex-column align-items-center">
                <small id="chaveHelp" class="form-text text-muted">Esqueceu sua chave?</small>
                <small id="chaveHelp" class="form-text text-muted">Entre em contato com o suporte.</small>    
            </div>
        </div>
    </div>
    <footer class="footer-acesso">

    </footer>
</div>
</template>
<script>
import utils from '@/utilitarios/utils.js'

export default {
    name: 'AcessarView',
    data(){
        return {
            isLoadingAcessar: false,
            
            fantasiaCliente: '',
            vCnpjCpf: '',
            vChaveAcesso: ''
        }
    },
    methods: {
        carregaInformacoesInput(){
            let vself = this;
            vself.fantasiaCliente = '';
            let cnpjCpf = this.vCnpjCpf.replace(/\D/g, '')
            if (cnpjCpf.length !== 11 && cnpjCpf.length !== 14) {
                return;
            }
            this.isLoadingAcessar = true;
            let parametros = [
                {parametro: 'op',           	valor: 'carregainfosinputacesso'},
                {parametro: 'cnpjcpf',          valor: cnpjCpf}
            ];
            let retorno = utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/rillpay/rtacesso.php');
            retorno.then(function (response) {
                vself.isLoadingAcessar = false;
                if (parseInt(response.data.inf) === 0) {
                    vself.$toastr.w('Cliente não encontrado! Verifique o CNPJ ou CPF informado.', 'Ops!');
                } else {
                    vself.fantasiaCliente = response.data.fantasiacli;
                }
            }).catch(function (response) {
                vself.$toastr.e('Erro ao carregar informações do cliente.', 'Ops!');
                console.log(response.message);
            });
        },
        logarCliente(){
            let vself = this;
            let cnpjCpf = this.vCnpjCpf.replace(/\D/g, '')
            if (cnpjCpf.length !== 11 && cnpjCpf.length !== 14) {
                return;
            }

            // if (this.vChaveAcesso.length < 5) {
            //     this.$toastr.w('Informe a chave de acesso.', 'Ops!');
            //     return;
            // }

            this.isLoadingAcessar = true;
            let parametros = [
                {parametro: 'op',           	valor: 'logarcliente'},
                {parametro: 'cnpjcpf',          valor: cnpjCpf},
                {parametro: 'chaveacesso',      valor: this.vChaveAcesso}
            ];
            let retorno = utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/rillpay/rtacesso.php');
            retorno.then(function (response) {
                vself.isLoadingAcessar = false;
                if (isNaN(response.data.inf)) {
                    vself.$toastr.w(response.data.inf, 'Ops!');
                    return;
                } else {
                    let payload = {
                        id			: response.data.codcli,
                        cnpjcpf		: response.data.cnpjcpf,
                        fantasia	: response.data.fantasiacli,
                        tpcliente	: response.data.tpcli,
                        imgcliente  : response.data.imgcliente
                    };
                    vself.$store.commit('MUDAR_USUARIO_LOGADO', payload);

                    window.localStorage.setItem('usuarioLogado', JSON.stringify(payload));

                    vself.$router.push({name: 'home'})
                }
            }).catch(function (response) {
                vself.isLoadingAcessar = false;
                vself.$toastr.e('Erro ao logar cliente.', 'Ops!');
                console.log(response.message);
            });
        }
    },
    mounted() {
        // pega o paramentro cnpjcpf e acesso da url
        let url = new URL(window.location.href);
        let cnpjcpf = url.searchParams.get('cnpjcpf');
        let acesso = url.searchParams.get('acesso');
        // se tiver cnpjcpf url preenche o campo
        if (cnpjcpf) {
            utils.deslogarUsuario(this);
            this.vCnpjCpf = cnpjcpf;
            this.carregaInformacoesInput();

            // se tiver acesso url preenche o campo
            if (acesso) {
                this.vChaveAcesso = acesso;
                this.logarCliente();
            }
        }
        
        if (window.localStorage.getItem('usuarioLogado')) {
            this.$router.push({name: 'home'})
        }
    },
    computed: {

    },
    watch: {

    }
}

</script>
<style scoped lang="scss">
#app-acesso {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background-color: #1e2b37;
    background-image: url('../assets/icone-rillsoft-white.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 150vh;

}

.container-acesso {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 60px;
    width: 350px;
    min-height: 450px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);
    padding: 30px;

    label {
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
    }
}

.footer-acesso {
    margin-top: auto;
    // background-color: #1e2b37;
    height: 50px;
}

.container-imagem-perfil{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    img {
        height: 50px;
    }
}

.remove-shadow-focus{
    box-shadow: none;
}
</style>